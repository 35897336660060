import { api, omitBlankEntries } from './api'
import { Promotion } from './promotions'

interface OperationsType {
  operation_type: string
  amount: number
}

interface ProductCopyKPK {
  extension: {
    is_available: boolean
    opens_in: number
    max_ext_days: number
    min_ext_days: number
    total_loan_term: number
  }
  kpk_fee: number
  penalty: number
  interest: number
  max_term: number
  min_term: number
  max_amount: number
  min_amount: number
  product_code: string
  product_name: string
  time_to_sign: number
  kpk_pending_fee: number
  kpk_extension_fee: number
}

export type Loan = {
  id: string
  short_name: string
  created_at: string
  updated_at: string
  request_id: string
  profile_id: string
  interest: string
  psk: number
  status: string
  term: number
  issue_date: string
  repayment_date: string
  closing_date: string
  amount_principal_accrued: number
  amount_principal_paid: number
  amount_interest_accrued: number
  amount_interest_paid: number
  amount_issuance_fee_accrued: number
  amount_issuance_fee_paid: number
  amount_ext_fee_accrued: number
  amount_ext_fee_paid: number
  amount_repayment_fee_accrued: number
  amount_repayment_fee_paid: number
  amount_premium_account_accrued: number
  amount_premium_account_paid: number
  amount_insurance_accrued: number
  amount_insurance_paid: number
  amount_penalty_accrued: number
  amount_penalty_paid: number
  amount_overpayment: number
  amount_writeoff: number
  prolongation_count: number
  last_login: string
  org_origin: string
  org_owner: string
  org_collect: string
  phone_prefix: number
  dpd: number
  ext_details: {
    amount_for_extension: number
    extension_available: boolean
    max_ext_days: number
    min_ext_days: number
  }
  extension_type_id: string
  amount_debt: number
  amount_principal_debt: number
  amount_interest_debt: number
  amount_fees_debt: number
  amount_ext_fees_debt: number
  amount_penalty_debt: number
  amount_insurance_debt: number
  amount_premium_account_debt: number
  amount_non_principal_debt: number
  amount_non_principal_debt_excl_penalty: number
  amount_non_principal_debt_excl_penalty_premium_insurance: number
  amount_non_principal_debt_excl_premium_insurance: number
  interest_frozen_end_date: string
  penalties_frozen_end_date: string
  consent_direct_debit: boolean | null
  timezone: string | null
  //for kpk + mkk
  product_copy: ProductCopyKPK | any //@TODO: check union work correct?
  //for kpk
  product_type: string
  operations: {
    accrued: OperationsType[] | []
    paid: OperationsType[] | []
    writeoff: OperationsType[] | []
    debt: OperationsType[] | []
  } //check can be null?
}

type LoanPayment = {
  id: string
  created_at: string
  updated_at: string
  profile_id: string
  last_name: string
  first_name: string
  middle_name: string
  loan_id: string
  direction: string
  amount: number
  accept_date: string
  is_accepted: boolean
  comment: string
}

type LoanOperation = {
  id: string
  created_at: string
  updated_at: string
  loan_id: string
  direction: string
  amount: number
  operation_type: string
}

type LoanCard = {
  data: string
}

type LoanSignedDocument = {
  doc_id: string
  doc_name: string
  id: string
  signed_at: string
  type: string
}

type LoanPurchaser = any

type LoanPromotion = {
  promotion_id: Promotion['id']
  promo_name: Promotion['promo_name']
  is_assigned: boolean
}

type FreezeAccrual = {
  start_date: string
  end_date: string
  interest_frozen: boolean
  penalties_frozen: boolean
  comment: string
}

type ConsentLoan = {
  receipt_date: string
}

type LoanPromoInfo = {
  id: number
  promo_name: string
  main_body_writeoff: number
  interest_writeoff: number
  fines_writeoff: number
  interest_freezing: boolean
  fines_freezing: boolean
  offer_duration: number
  activation_date: string
  expiration_date: string
}

export type ExternalService = {
  id: number
  amount: number
  created_at: string
  external_service_id: string
  name: string
  tag: string
  amount_paid: number
}

type WriteOffType = {
  status_id: number
  status_description: string
}

const loansApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLoan: builder.query<Loan, string>({
      query: (id) => `crm/v1/loans/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Loans', id }]
    }),
    getLoanPayments: builder.query<LoanPayment[], string>({
      query: (id) => `crm/v1/loans/${id}/funds-transactions`,
      providesTags: (_result, _err, id) => [{ type: 'LoanPayments', id }]
    }),
    getLoanOperations: builder.query<LoanOperation[], string>({
      query: (id) => `crm/v1/loans/${id}/operations`,
      providesTags: (_result, _err, id) => [{ type: 'LoanOperations', id }]
    }),
    getLoanCard: builder.query<LoanCard, string>({
      query: (id) => `crm/v1/loans/${id}/card`,
      providesTags: (_result, _err, id) => [{ type: 'LoanCards', id }],
      transformResponse: ({ data }) => data
    }),
    getLoanSignedDocuments: builder.query<LoanSignedDocument[], string>({
      query: (id) => `crm/v1/loans/${id}/signed-documents`,
      providesTags: (_result, _err, id) => [{ type: 'LoanSignedDocuments', id }]
    }),
    getLoanPurchaser: builder.query<LoanPurchaser, string>({
      query: (id) => `crm/v1/loans/${id}/purchaser`,
      providesTags: (_result, _err, id) => [{ type: 'LoanPurchasers', id }]
    }),
    getLoanPromotion: builder.query<LoanPromotion, string>({
      query: (id) => `crm/v1/loans/${id}/available-loan-promo`,
      providesTags: (_result, _err, id) => [{ type: 'LoanPromotions', id }]
    }),
    setLoanPromotionAsActive: builder.mutation<
      void,
      { loanId: Loan['id']; promotionId: Promotion['id'] }
    >({
      query: ({ loanId, promotionId }) => ({
        url: `crm/v1/loans/${loanId}/loan-promo`,
        method: 'POST',
        params: { promo_id: promotionId }
      }),
      invalidatesTags: (_response, _error, { loanId }) => [
        { type: 'LoanPromotions', id: loanId }
      ]
    }),
    setLoanAsFraudulent: builder.mutation<void, string>({
      query: (id) => ({
        url: `crm/v1/loans/${id}/fraud`,
        method: 'POST'
      }),
      invalidatesTags: (_response, _error, id) => [
        { type: 'Loans', id },
        { type: 'LoanItems', id }
      ]
    }),
    setFreezeAccrual: builder.mutation<
      void,
      { loanId: Loan['id']; body: FreezeAccrual }
    >({
      query: ({ loanId, body }) => ({
        url: `crm/v1/loans/${loanId}/freeze-accrual`,
        method: 'POST',
        body: body
      })
    }),
    getFreezeAvailable: builder.query<Loan['id'], string>({
      query: (id) => `crm/v1/loans/${id}/freeze-available`,
      providesTags: (_result, _err, id) => [{ type: 'Loans', id }]
    }),
    setConsetLoan: builder.mutation<
      void,
      { loanId: string; body: ConsentLoan }
    >({
      query: ({ loanId, body }) => ({
        url: `crm/v1/consent/${loanId}/cdd-cancel`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['Loans']
    }),
    reloadContract: builder.mutation<void, { loanId: string }>({
      query: ({ loanId }) => ({
        url: `crm/v1/loans/${loanId}/reload-contract`,
        method: 'POST'
      }),
      invalidatesTags: () => ['Loans']
    }),
    getServicesDoc: builder.mutation<Blob, string>({
      query: (loan_id) => ({
        url: `/crm/v1/loans/${loan_id}/services-doc`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
        cache: 'no-cache'
      })
    }),
    setProlongation: builder.mutation<
      void,
      { loanId: string; body: { days: number } }
    >({
      query: ({ loanId, body }) => ({
        url: `/crm/v1/loans/${loanId}/prolongation`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['Loans']
    }),
    setWriteoff: builder.mutation<
      void,
      { loanId: string; body: { amount: number; writeoff_type: number } }
    >({
      query: ({ loanId, body }) => ({
        url: `/crm/v1/loans/${loanId}/writeoff`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['Loans']
    }),
    getWriteoffTypes: builder.query<WriteOffType[], void>({
      query: () => ({
        url: `/crm/v1/loans/writeoff-types`
      }),
      providesTags: () => ['WriteOffTypes']
    }),
    deleteWriteoffType: builder.mutation<WriteOffType, string>({
      query: (status) => ({
        url: `/crm/v1/loans/writeoff-types/${status}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => ['WriteOffTypes']
    }),
    updateWriteoffType: builder.mutation<
      WriteOffType,
      { new_writeoff_status: string; status: string }
    >({
      query: ({ new_writeoff_status, status }) => ({
        url: `/crm/v1/loans/writeoff-types/${status}`,
        method: 'PUT',
        params: { new_writeoff_status }
      }),
      invalidatesTags: () => ['WriteOffTypes']
    }),
    createWriteoffType: builder.mutation<void, { status: string }>({
      query: ({ status }) => ({
        url: `/crm/v1/loans/writeoff-types`,
        method: 'POST',
        params: { writeoff_status: status }
      }),
      invalidatesTags: () => ['WriteOffTypes']
    }),
    signDocsPromotion: builder.mutation<void, { profile_id: string }>({
      query: (params) => ({
        url: `/crm/v1/loans/sign-docs-promotion`,
        method: 'POST',
        params: omitBlankEntries(params)
      })
    }),
    getLoanPromoInfo: builder.query<LoanPromoInfo, string>({
      query: (loanId) => `/crm/v1/loans/${loanId}/loan-info`
    }),
    getExternalServices: builder.query<ExternalService[], string>({
      query: (loanId) => `/crm/v1/loans/${loanId}/services`
    }),
    generateDocuments: builder.mutation<
      Blob,
      { loanId: string; documentIds: number[] }
    >({
      query: ({ loanId, documentIds }) => ({
        url: `/crm/v1/loans/${loanId}/generate-documents`,
        method: 'POST',
        body: documentIds,
        responseHandler: (response) => response.blob(),
        formData: true
      })
    })
  })
})

export const {
  useGetLoanQuery,
  useGetLoanPaymentsQuery,
  useGetLoanOperationsQuery,
  useGetLoanCardQuery,
  useGetLoanSignedDocumentsQuery,
  useGetLoanPurchaserQuery,
  useGetLoanPromotionQuery,
  useSetLoanPromotionAsActiveMutation,
  useSetLoanAsFraudulentMutation,
  useSetFreezeAccrualMutation,
  useGetFreezeAvailableQuery,
  useSetConsetLoanMutation,
  useReloadContractMutation,
  useGetServicesDocMutation,
  useSetProlongationMutation,
  useSetWriteoffMutation,
  useGetWriteoffTypesQuery,
  useSignDocsPromotionMutation,
  useGetLoanPromoInfoQuery,
  useGetExternalServicesQuery,
  useCreateWriteoffTypeMutation,
  useDeleteWriteoffTypeMutation,
  useUpdateWriteoffTypeMutation,
  useGenerateDocumentsMutation
} = loansApi
